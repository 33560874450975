import { ConfigModel } from '../models/config.model';
import { apps } from './apps.factory';

export function getConfig(): ConfigModel {
  const origin = window.location.origin;
  if (/localhost:4200/.test(origin)) {
    return getDev();
  }
  if (/dev.developers.planzer.app/.test(origin)) {
    return getDev();
  }

  throw Error('Environment url is not supported');
}

function getDev(): ConfigModel {
  const serverUrl = 'https://dev.api.planzer.app';
  prefixServerUrl(serverUrl);

  return {
    authConfig: {
      clientId: '6fdf6a4c-799c-4212-aa87-0eece80fa7d1-b5e7b5c4-ecda-4420-3a7b-08dabe5c547b',
      issuer: 'https://dev.id.planzer.app',
      redirectUri: 'https://dev.developers.planzer.app',
      responseType: 'code',
      scope: '.default openid profile email offline_access',
      resource: serverUrl,
    },
    statusPageUrl: 'https://status.t7p.io',
    appInsights: {
      connectionString:
        'InstrumentationKey=6c2255a4-6b3e-4024-bf42-b6235394a5ce;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
    },
    apps: apps,
  };
}

function prefixServerUrl(serverUrl: string): void {
  apps.forEach((app) => {
    app.openApiUrl = `${serverUrl}${app.openApiUrl}`;
    app.serverUrl = `${serverUrl}${app.serverUrl}`;
  });
}
