import { AuthConfig } from 'angular-oauth2-oidc';
import { AuthConfigModel } from '../models/auth-config.model';

export function createAuthConfig(authConfigModel: AuthConfigModel, showDebugInformation: boolean): AuthConfig {
  const customQueryParams: { [key: string]: string } = {
    resource: authConfigModel.resource,
  };

  return {
    ...authConfigModel,
    showDebugInformation,

    //by default the resource parameter is not getting sent to the token endpoint
    resource: undefined,
    customQueryParams: customQueryParams,
  };
}
