import { Route } from '@angular/router';
import { LayoutComponent } from '@mds/design-luna';
import { marker as _ } from '@ngneat/transloco-keys-manager/marker';

export const appRoutes: Route[] = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.routes'),
        title: _('Dev.Navigation.Home.Title'),
      },
      {
        path: 'api',
        loadChildren: () => import('./api/api.routes'),
        title: _('Dev.Navigation.Api.Title'),
      },
      {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.routes'),
        title: _('Dev.Navigation.Authentication.Title'),
      },
      {
        path: 'errors/:code',
        loadComponent: () => import('@mds/design-luna').then((mod) => mod.ErrorPageComponent),
        title: _('Dev.Navigation.Error.Title'),
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: '**',
        redirectTo: 'errors/404',
      },
    ],
  },
];
