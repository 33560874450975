import { Observable, of } from 'rxjs';

import { Translation, TranslocoLoader } from '@ngneat/transloco';
import { de } from './de';
import { en } from './en';
import { fr } from './fr';
import { it } from './it';

export class TranslateAuthenticationLoader implements TranslocoLoader {
  getTranslation(lang: string): Observable<Translation> {
    let translations = {};
    switch (lang) {
      case 'de':
        translations = de;
        break;
      case 'fr':
        translations = fr;
        break;
      case 'it':
        translations = it;
        break;
      case 'en':
      default:
        translations = en;
    }

    return of(translations);
  }
}
