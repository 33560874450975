import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TitleStrategy, provideRouter, withEnabledBlockingInitialNavigation, withRouterConfig } from '@angular/router';
import { provideAuthentication } from '@developers/authentication';
import {
  LocalizationService,
  MDS_CONFIG_SERVICE,
  MDS_DEFAULT_CULTURE_PROVIDER,
  MDS_SUPPORTED_CULTURES,
  MDS_TRANSLATE_LOADERS,
  MDS_TRANSLATE_PROVIDER,
  loadLocalization,
  provideCore,
} from '@mds/angular-core';
import {
  Brand,
  ERROR_PAGES_NAVIGATION,
  ErrorNavigation,
  LUNA_BRAND,
  LUNA_HEADER_ITEMS,
  LUNA_NAVIGATION_ITEMS,
  provideLunaLayout,
} from '@mds/design-luna';
import { TranslocoPipe, provideTransloco } from '@ngneat/transloco';
import { of } from 'rxjs';
import { appRoutes } from './app.routes';
import { StatusComponent } from './shared/components/status/status.component';
import { supportedCultures } from './shared/config/culture.config';
import { WebpackTranslateLoader } from './shared/config/translate-loader';
import { getConfig } from './shared/factories/config.factory';
import { createNavigationItems } from './shared/factories/navigation.factory';
import { ConfigModel, DEV_CONFIG } from './shared/models/config.model';
import { StaticConfigService } from './shared/services/static-config.service';
import { TranslatedTitleStrategy } from './shared/services/translated-title-strategy.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withEnabledBlockingInitialNavigation(),
      withRouterConfig({
        // This improves how canDeactivate guards work. Otherwise, pressing back
        // twice will navigate back no matter what.
        canceledNavigationResolution: 'computed',
      })
    ),
    // see https://manfredsteyer.github.io/angular-oauth2-oidc/docs/additional-documentation/working-with-httpinterceptors.html
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: LUNA_HEADER_ITEMS,
      useValue: StatusComponent,
      multi: true,
    },
    provideAnimations(),
    provideCore(),
    provideLunaLayout(),
    provideAuthentication<ConfigModel>((x: ConfigModel) => x.authConfig),
    MDS_DEFAULT_CULTURE_PROVIDER,
    { provide: MDS_TRANSLATE_LOADERS, useClass: WebpackTranslateLoader, multi: true },
    provideTransloco(MDS_TRANSLATE_PROVIDER),
    { provide: MDS_SUPPORTED_CULTURES, useValue: supportedCultures },
    { provide: TranslocoPipe },
    {
      provide: MDS_CONFIG_SERVICE,
      useClass: StaticConfigService,
      deps: [DEV_CONFIG],
    },
    { provide: LUNA_NAVIGATION_ITEMS, useFactory: createNavigationItems },
    { provide: DEV_CONFIG, useFactory: getConfig },
    {
      provide: TitleStrategy,
      useClass: TranslatedTitleStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadLocalization,
      deps: [LocalizationService],
      multi: true,
    },
    {
      provide: ERROR_PAGES_NAVIGATION,
      useValue: {
        homePageUrl: '/',
        supportPageUrl: 'https://www.planzer.ch/ch/kontakt-ch/',
      } satisfies ErrorNavigation,
    },
    {
      provide: LUNA_BRAND,
      useValue: of(<Brand>{
        url: 'https://assets.planzer.app/image/brands/',
        name: 'planzer.svg',
      }),
    },
  ],
};
