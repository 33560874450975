import { ICulture, supportedCultures as supportedCoreCultures } from '@mds/angular-core';

export const configuredIsoCodes = [
  'en',
  // These languages are currently not translated, and the interface will
  // show empty values.
  // 'de', 'it', 'fr'
] as const;

export type IsoCode = (typeof configuredIsoCodes)[number];

export const supportedCultures: ICulture[] = [
  ...supportedCoreCultures.filter((c) => (configuredIsoCodes as readonly string[]).includes(c.isoCode)),
];
