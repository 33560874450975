import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, IConfiguration, MDS_TRANSLATE_LOADERS } from '@mds/angular-core';
import { LUNA_PROFILE_ITEMS } from '@mds/design-luna';
import { OAuthModule } from 'angular-oauth2-oidc';
import { createProfileItems } from './config/profile-items.factory';
import { TranslateAuthenticationLoader } from './i18n/translate-authentication-loader';
import { AuthConfigModel, MDS_AUTH_CONFIG_MAP, MDS_AUTH_DEBUG } from './models/auth-config.model';
import { AuthenticationService } from './services/authentication.service';

export function provideAuthentication<TConfiguration extends IConfiguration>(
  authConfigMap: (config: TConfiguration) => AuthConfigModel,
  options?: {
    showDebugInformation?: boolean;
  }
): EnvironmentProviders {
  return makeEnvironmentProviders([
    ...(OAuthModule.forRoot().providers ?? []),
    AuthenticationService,
    { provide: AuthService, useExisting: AuthenticationService },
    { provide: MDS_AUTH_DEBUG, useValue: options?.showDebugInformation ?? false },
    { provide: MDS_AUTH_CONFIG_MAP, useValue: authConfigMap },
    {
      provide: LUNA_PROFILE_ITEMS,
      useFactory: createProfileItems,
      deps: [AuthService, Router],
      multi: true,
    },
    { provide: MDS_TRANSLATE_LOADERS, useClass: TranslateAuthenticationLoader, multi: true },
  ]);
}
