import { NavigationItem } from '@mds/design-luna';
import { marker as _ } from '@ngneat/transloco-keys-manager/marker';
import { Observable, of } from 'rxjs';

export const createNavigationItems = (): Observable<NavigationItem[]> => {
  return of([
    {
      icon: 'mds mds-home',
      labelResource: _('Dev.Navigation.Home.Title'),
      routerLink: 'home',
    },
    {
      icon: 'mds mds-application',
      labelResource: _('Dev.Navigation.Api.Title'),
      routerLink: 'api',
    },
    {
      icon: 'pi pi-shield',
      labelResource: _('Dev.Navigation.Authentication.Title'),
      routerLink: 'authentication',
    },
  ]);
};
