import { InjectionToken } from '@angular/core';
import { IConfiguration } from '@mds/angular-core';

export const DEV_CONFIG = new InjectionToken<ConfigModel>('DEV_CONFIG');

export interface ConfigModel extends IConfiguration {
  statusPageUrl: string;
  apps: AppModel[];
  authConfig: AuthConfigModel;
}

export interface AuthConfigModel {
  clientId: string;
  redirectUri: string;
  issuer: string;
  responseType: string;
  scope: string;
  resource: string;
}

export interface AppModel {
  /**
   * Unique key used for routing
   */
  id: string;

  /**
   * Relative base url where the try out requests will be sent to
   */
  serverUrl: string;

  /**
   * Relative url to fetch the Open API specification from
   */
  openApiUrl: string;

  /**
   * Resource for the app name
   */
  nameResource: string;

  /**
   * Resource for the app description
   */
  descriptionResource: string;

  /**
   * Optional, resource for what the app can be used for
   */
  whatResource?: string;

  /**
   * Optional, resource for how the app can be used
   */
  howResource?: string;

  /**
   * Optional, resource for who the app is for
   */
  whoResource?: string;
}
