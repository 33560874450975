import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ConfigBaseService, MDS_CONFIG_SERVICE } from '@mds/angular-core';
import { ButtonModule } from 'primeng/button';
import { Observable, switchMap, timer } from 'rxjs';
import { ConfigModel } from '../../models/config.model';
import { StatusPageModel } from '../../models/status-page.model';
import { StatusPageService } from '../../services/status-page.service';

@Component({
  selector: 'dev-status',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusComponent {
  private readonly everyFiveMinutes = 1000 * 60 * 5;
  readonly page$: Observable<StatusPageModel>;

  constructor(
    statusPageService: StatusPageService,
    @Inject(MDS_CONFIG_SERVICE) private readonly configService: ConfigBaseService
  ) {
    this.page$ = timer(0, this.everyFiveMinutes).pipe(switchMap(() => statusPageService.getStatusPage()));
  }

  openStatusPage(): void {
    this.configService.isInitialized$.subscribe(() => {
      window.open(this.configService.getConfig<ConfigModel>().statusPageUrl);
    });
  }
}
