import { Inject, Injectable } from '@angular/core';
import { ConfigBaseService, IConfiguration } from '@mds/angular-core';
import { Observable, of } from 'rxjs';
import { DEV_CONFIG } from '../models/config.model';

@Injectable({
  providedIn: 'root',
})
export class StaticConfigService extends ConfigBaseService {
  readonly isInitialized$ = of(true);

  constructor(@Inject(DEV_CONFIG) private readonly config: any) {
    super();
  }

  getConfig<TConfiguration extends IConfiguration = IConfiguration>(): TConfiguration {
    return this.config as TConfiguration;
  }

  load(): Observable<boolean> {
    return of(true);
  }
}
