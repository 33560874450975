@if (page$ | async; as page) { @switch (page.status.indicator) { @case ('none') {
<button
  pButton
  class="p-button-link"
  label="Status"
  (click)="openStatusPage()"
  icon="pi pi-check-circle"
  [title]="page.status.description"
></button>
} @case ('major') {
<button
  pButton
  class="p-button-link"
  label="Status"
  (click)="openStatusPage()"
  icon="pi pi-exclamation-triangle"
  [title]="page.status.description"
></button>
} @case ('minor') {
<button
  pButton
  class="p-button-link"
  label="Status"
  (click)="openStatusPage()"
  icon="pi pi-exclamation-circle"
  [title]="page.status.description"
></button>
} @default {
<button pButton class="p-button-link" label="Status" (click)="openStatusPage()"></button>
} } } @else {
<button pButton class="p-button-link" label="Status" (click)="openStatusPage()" [loading]="true"></button>
}
