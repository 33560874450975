import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApiClientBase, ConfigBaseService, MDS_CONFIG_SERVICE } from '@mds/angular-core';
import { Observable, map, shareReplay, switchMap } from 'rxjs';
import { ConfigModel } from '../models/config.model';
import { StatusPageModel } from '../models/status-page.model';

@Injectable({
  providedIn: 'root',
})
export class StatusPageService extends ApiClientBase {
  private readonly config$: Observable<ConfigModel>;

  constructor(private readonly http: HttpClient, @Inject(MDS_CONFIG_SERVICE) configService: ConfigBaseService) {
    super();

    this.config$ = configService.isInitialized$.pipe(
      map(() => configService.getConfig<ConfigModel>()),
      shareReplay(1)
    );
  }

  getStatusPage(): Observable<StatusPageModel> {
    return this.config$.pipe(
      switchMap((config) => {
        let url = config.statusPageUrl + '/api/v2/status.json';
        url = url.replace(/[?&]$/, '');

        return this.handleResponse(this.http.get<StatusPageModel>(url, this.jsonOptions));
      })
    );
  }
}
